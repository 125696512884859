import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {setAlert, setLoading} from '../features/main_functions.js';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import {awsRum} from '../aws.rum.config.js';

/**
 * Custom hook for making HTTP requests with axios
 *
 * @param {Object} options - Configuration options for the request
 * @param {string} options.url - The URL endpoint to make the request to
 * @param {boolean} options.loading - Whether to show loading state during request
 * @param {Object|FormData} options.body - Request body data for POST requests
 * @param {boolean} options.upload - Whether this is a file upload request
 * @param {boolean} options.returnError - Whether to return error responses
 * @returns {Object} Object containing:
 *   - data: Response data if request successful
 *   - error: Error object if request failed
 *   - fetchData: Function to trigger the request
 *
 * This hook provides a reusable way to make HTTP requests with consistent error handling,
 * loading states, and internationalization support. It integrates with Redux for global
 * state management and AWS RUM for error tracking.
 *
 * The hook handles:
 * - GET and POST requests
 * - File uploads with progress tracking
 * - Error handling and navigation to maintenance page for 500 errors
 * - Loading state management
 * - Internationalization of requests
 * - Authentication via token
 */

function useFetch({url = '', loading = false, body = '', upload = false, returnError = false}) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const baseURL = import.meta.env.VITE_URL;

  const fetchData = useCallback(async ({url, loading, body, setUploadProgress, upload, hook, returnError, auth}) => {
    let config = {
      url: baseURL + url, method: body ? 'POST' : 'GET', headers: {
        'Accept-Language': i18next.language || 'en', Authorization: localStorage.token
      }
    };

    setUploadProgress && upload && (config.onUploadProgress = (e) => setUploadProgress((o) => ({
      ...o, [body.name]: Number(e.progress * 100).toFixed(1)
    })));
    body && (upload ? (config.data = body.data) : (config.data = body));
    body?.signal && upload && (config.signal = body.signal);

    console.log(config);
    loading && dispatch(setLoading(true));

    let result = await axios(config)
      .then((res) => res)
      .then(({data}) => (upload ? data : data.result))
      .catch((error) => {
        if (error?.response?.status >= 500) {
          navigate('/maintenance');
        }
        awsRum?.recordError(error);
        hook && setError(error);

        return error;
      });
    loading && dispatch(setLoading(false));

    // console.log(result);
    let returnData = null;

    if ([200, 201, 204].includes(parseInt(result?.status))) {
      hook && setData(result);
      returnData = result;
    } else if ([400, 401, 404, 409, 498].includes(parseInt(result?.status))) {
      if (result.error?.error == 'Invalid Token') {
        localStorage.clear();
        navigate('/');
        result.error.error = t('alert_box.session_expired');
      } else {
        awsRum?.recordError(error);

        if (returnError) {
          hook && setData(result);
          returnData = result;
        } else {
          hook && setError(result.error?.error || result.error?.message || result.error);
          (result.error?.error || result.error) && dispatch(setAlert({
            txt: t(`response.${result.error?.error || result.error?.message || result.error}`, {
              defaultValue: result.error?.error || result.error?.message || result.error
            }), type: 'danger'
          }));
        }
      }
    } /*  else if (result?.code == 'ERR_NETWORK') {
        navigate('/maintenance');
      } */ else if (result?.code != 'ERR_CANCELED') {
      dispatch(setAlert({txt: t('alert_box.unexpected'), type: 'danger'}));
    }
    setUploadProgress && setUploadProgress((o) => ({
      ...o, [body.name]: false
    }));

    return returnData;
  }, [url]);

  useEffect(() => {
    url && fetchData({url, loading, body, upload, hook: true, returnError});
  }, [url]);

  return {data, error, fetchData};
}

export default useFetch;
