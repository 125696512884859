import 'animate.css';
import './localization/i18n.js';
import useFetch from './hooks/useFetch.js';
import {useEffect, useLayoutEffect, useState} from 'react';
import {setEntityDetails} from './features/main_functions.js';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import Tracker from '@openreplay/tracker';
import {Tooltip} from 'bootstrap/dist/js/bootstrap.bundle.min.js';

/* 
- configuration file for each entity
*/

export {Tooltip}

export const tracker = new Tracker({
  ingestPoint: 'https://insights.plusonehealth.online/ingest',
  projectKey: import.meta.env.VITE_OPEN_REPLAY_KEY,
  capturePerformance: true,
  __DISABLE_SECURE_MODE: import.meta.env.VITE_DISABLE_SECURE_MODE || false
});

function SiteConfig() {
  const [entityError, setEntityError] = useState(false);

  const dispatch = useDispatch();

  const {
    VITE_MAIN_COLOR,
    VITE_SUB_COLOR,
    VITE_NODE_ENV,
    VITE_ICON_COLOR,
    VITE_PATIENT_PORTAL,
    VITE_EXPERT_PORTAL,
    VITE_OPERATION_PORTAL,
    VITE_DARK_SUB_COLOR
  } = import.meta.env;

  useEffect(() => {
    if (localStorage.lang === 'fr') {
      import('moment/dist/locale/fr.js').then(() => {
        import('moment').then((m) => m.default.locale('fr'));
      });
    } else {
      import('moment').then((m) => m.default.locale('en'));
    }
  }, [localStorage.lang]);

  /*   useEffect(() => {
    tracker.use(trackerAssist());
    tracker.start();
  }, []); */
  /* this code adds the website color from .env file which is unique for each entity */
  /* --main-color: main website color */
  /* --sub-color: sub website color */
  const cssVariables = `
      :root {
        --main-color: ${VITE_MAIN_COLOR || '#067787'};
        --light-sub-color: ${VITE_SUB_COLOR || '#e1eff1'};
        --dark-sub-color: ${VITE_DARK_SUB_COLOR || '#13272a'};
        --icon-color: ${
    VITE_ICON_COLOR ||
    'invert(40%) sepia(94%) saturate(6261%) hue-rotate(172deg) brightness(88%) contrast(95%)'
  };
      }
      `;
  const style = document.createElement('style');
  style.textContent = cssVariables;
  document.head.append(style);

  //api to get entity details from backend (site title, main logo, header logo)
  const {fetchData} = useFetch({});

  const {pathname} = useLocation();
  const navigate = useNavigate();

  if (VITE_NODE_ENV !== 'dev') console.log = () => {
  }; // disbale/enable logs in dev/production mode

  //function to add payment gateway url in index.html
  const addPaymentGateway = (url) => {
    url = url.replace('api', 'static');
    const externalStylesheet = document.createElement('link');
    externalStylesheet.rel = 'stylesheet';
    externalStylesheet.href = url + '/static/js/krypton-client/V4.0/ext/neon.css';

    /* const externalScript = document.createElement('script');
    externalScript.src = url + '/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js'; */

    document.head.append(externalStylesheet);
  };

  useLayoutEffect(() => {
    document.documentElement.lang = localStorage.lang || 'en';
    document.dir = localStorage.lang == 'ar' ? 'rtl' : 'ltr';
  }, []);

  //function to add the site title and logos to the head tag and the navbar
  useEffect(() => {
    (async () =>
      await fetchData({
        url: 'entity/details',
        body: {
          'Portal-Token': VITE_PATIENT_PORTAL || VITE_EXPERT_PORTAL || VITE_OPERATION_PORTAL
        },
        loading: true,
        returnError: true
      }).then((result) => {
        if (result?.result?.entity) {
          if (pathname == '/maintenance') {
            navigate('/');
          }

          const entity = result.result.entity;
          dispatch(setEntityDetails(entity));

          const title = document.createElement('title');
          const favicon = document.createElement('link');
          const applefavicon = document.createElement('link');
          const mainWebsite = document.createElement('meta');

          if (entity.payment_gateways?.length > 0)
            addPaymentGateway(entity.payment_gateways[0].payment_request_url);

          title.textContent = entity.name + ' - Second Opinion' || 'Website';
          favicon.rel = 'icon';
          applefavicon.rel = 'apple-touch-icon';
          favicon.href = applefavicon.href = entity.header_logo;
          mainWebsite.name = 'main_website';
          mainWebsite.content = entity.main_website;

          document.head.append(title, favicon, applefavicon, mainWebsite);
        } else if (result?.code) {
          navigate('/maintenance');
        } else {
          setEntityError(true);
        }
      }))();
  }, []);

  useEffect(() => {
    if (entityError) throw new Error('Failed to fetch entity details');
  }, [entityError]);

  return;
}

export default SiteConfig;
