import { useEffect, useState } from 'react';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import { awsRum } from '../../aws.rum.config.js';
import useFetch from '../../hooks/useFetch.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { tracker } from '../../config.js';

const CookieManager = () => {
  const user = useSelector((state) => state.mainFun.user);

  const { fetchData } = useFetch({});
  const { t } = useTranslation();

  const addConsentToUser = async (consent) => {
    if (localStorage.token)
      await fetchData({ url: 'website/cookies/set', body: { params: { consent } } }).then((res) => {
        if (res) {
          window.location.reload();
          localStorage.setItem('consentSigned', true);
        }
      });
  };

  useEffect(() => {
    if (!localStorage.consentSigned && CookieConsent.validConsent())
      addConsentToUser(CookieConsent.getCookie());
  }, [user]);

  useEffect(() => {
    CookieConsent.run({
      onChange: ({ cookie }) => addConsentToUser(cookie),
      onFirstConsent: ({ cookie }) => addConsentToUser(cookie),
      revision: 3,
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
          services: {
            cc_cookie: {
              label: 'cc_cookie',
              cookies: [{ name: 'cc_cookie' }]
            },
            session_id: {
              label: 'session_id',
              cookies: [{ name: 'session_id', domain: import.meta.env.VITE_URL }]
            },
            frontend_lang: {
              label: 'frontend_lang',
              cookies: [{ name: 'frontend_lang', domain: import.meta.env.VITE_URL }]
            },
            tz: {
              label: 'tz',
              cookies: [{ name: 'tz', domain: import.meta.env.VITE_URL }]
            },
            cids: {
              label: 'cids',
              cookies: [{ name: 'cids', domain: import.meta.env.VITE_URL }]
            }
          }
        },
        analytics: {
          autoClear: {
            cookies: [{ name: 'cwr_s' }, { name: 'cwr_u' }]
          },
          services: {
            rum: {
              label: 'Amazon cloud watch RUM',
              cookies: [{ name: 'cwr_s' }, { name: 'cwr_u' }]
            }
          }
        }
      },

      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: t('cookies.title'),
              description: t('cookies.cookies_description'),
              acceptAllBtn: t('cookies.accept'),
              acceptNecessaryBtn: t('cookies.reject'),
              showPreferencesBtn: t('cookies.manage')
            },
            preferencesModal: {
              title: t('cookies.prefrences'),
              acceptAllBtn: t('cookies.accept'),
              acceptNecessaryBtn: t('cookies.reject'),
              savePreferencesBtn: t('cookies.accept_current'),
              closeIconLabel: t('cookies.close'),
              sections: [
                {
                  title: t('cookies.neccessary_title'),
                  description: t('cookies.neccessary_description'),
                  linkedCategory: 'necessary'
                },
                {
                  title: t('cookies.performance_title'),
                  description: t('cookies.performance_description'),
                  linkedCategory: 'analytics'
                },
                {
                  title: t('cookies.tokens_title'),
                  description: t('cookies.tokens_description')
                },
                {
                  title: t('cookies.privacy_title'),
                  description: t('cookies.privacy_description')
                }
              ]
            }
          }
        }
      },
      disablePageInteraction: true,
      autoClearCookies: true
    });
  }, []);

  useEffect(() => {
    if (!CookieConsent.validConsent()) {
      localStorage.removeItem('consentSigned');
    }
  }, []);

  useEffect(() => {
    if (CookieConsent.getUserPreferences().acceptedServices?.analytics?.includes('rum')) {
      awsRum?.enable();
    } else {
      CookieConsent.eraseCookies(['cwr_u', 'cwr_s']);
    }
    // if (CookieConsent.getUserPreferences().acceptedServices?.analytics?.includes('tracker')) {
    //   tracker.start();
    // } else {
    //   tracker.stop();
    // }
    Object.values(CookieConsent.getUserPreferences().rejectedServices).forEach((obj) =>
      CookieConsent.eraseCookies(obj)
    );
  }, [CookieConsent]);

  return <></>;
};

export default CookieManager;
