import common from './common.json';
import alertBox from './alertBox.json';
import caseView from './caseView.json';
import newCase from './newCase.json';
import errors from './errors.json';

export default {
  ...common,
  ...alertBox,
  ...caseView,
  ...newCase,
  ...errors
};
