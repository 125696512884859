import {useEffect, useLayoutEffect, useState} from 'react';
import DropDown from './Dropdown.jsx';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import ukFlag from '../assets/images/flags/uk.svg';
import frFlag from '../assets/images/flags/fr.svg';
import arFlag from '../assets/images/flags/ar.svg';
import moment from 'moment';
import {awsRum} from '../aws.rum.config.js';

function LanguageDropdown({showText = false}) {
  const [lang, setLang] = useState();
  const [languageOptions, setLanguageOptions] = useState({});
  const {i18n} = useTranslation();

  const enabledLanguages =
    import.meta.env.VITE_ENABLE_LOCALIZATION && import.meta.env.VITE_ENABLE_LOCALIZATION.split(',');
  // console.log("🚀 ~ LanguageDropdown ~ enabledLanguages:", enabledLanguages)
  //
  //   console.log(import.meta.env.VITE_ENABLE_LOCALIZATION);


  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
      awsRum?.recordEvent('user_change_language', {version: '1.0.0', language: lang});
      localStorage.setItem('lang', lang);
      moment.locale(lang);
      window.location.reload();
    }
  }, [lang]);

  useLayoutEffect(() => {
    if (enabledLanguages?.length > 0) {
      const options = {
        en: {
          txt: 'EN',
          img: ukFlag,
          action: () => setLang('en')
        },
        fr: {
          txt: 'FR',
          img: frFlag,
          action: () => setLang('fr')
        },
        ar: {
          txt: 'AR',
          img: arFlag,
          action: () => setLang('ar'),
          show: enabledLanguages?.includes('ar')
        }
      };
      const obj = {
        en: options.en
      };
      enabledLanguages?.forEach((lang) => {
        obj[lang] = options[lang];
      });
      setLanguageOptions(obj);
    }
  }, []);

  return (
    Object.keys(languageOptions)?.length > 0 && (
      <DropDown
        txt={showText ? i18next.language : ''}
        img={languageOptions[i18next.language]?.img}
        imgFilter={false}
        className="d-flex gap-3 align-items-center bg-transparent p-1 text-uppercase border-0  primary-txt"
        option={Object.values(languageOptions)}
      />
    )
  );
}

export default LanguageDropdown;
