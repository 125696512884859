import { Navigate, Route, useLocation } from 'react-router-dom';
import { Suspense, lazy, useCallback, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserDetails } from './features/main_functions.js';
import useFetch from './hooks/useFetch.js';
import { Layout } from './layouts/Layout.jsx';
import { tracker } from './config.js';

function PortalApp({ role, children }) {
  const dispatch = useDispatch();
  const { fetchData } = useFetch({});
  const { pathname } = useLocation();

  const { token } = localStorage;

  const fetchUserRole = useCallback(async () => {
    await fetchData({ url: 'user/type', loading: false, body: {} }).then((result) => {
      if (result?.result) {
        tracker.setUserID(result.result.user_type + ' - ' + result.result.email);
        dispatch(setUserDetails(result.result));
      }
    });
  }, []);

  useLayoutEffect(() => {
    if (token) {
      fetchUserRole();
    }
  }, [token]);

  return (
    <Layout>
      <Route exact path="/" element={<Navigate to={`/${role}/home`} />} />
      <Route exact path={`/${role}/*`} element={<main>{children}</main>} />
    </Layout>
  );
}

export default PortalApp;
